@include mk-component-palette('.chip');

.chip {
  cursor: inherit !important;
  font-size: rem-calc(12);
  border-radius: rem-calc(100);
  padding: rem-calc(7) rem-calc(12);
  transition: .2s all;
  min-height: rem-calc(6);
  line-height: initial;
  display: inline-block;

  &--big {
    font-size: rem-calc(16);
  }

  &--status {
    @extend .light;
    font-size: rem-calc(11);
    letter-spacing: rem-calc(.5);
    padding: rem-calc(4) rem-calc(9);
    height: auto;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: font-weight(medium);

    &.confirmed {
      @extend .chip--success--light;
    }

    &.canceled,
    &.danger {
      @extend .chip--alert--light;
    }

    &.processing,
    &.info {
      @extend .chip--info--light;
    }

    &.unconfirmed {
      @extend .chip--warning--light;
    }

    &.condensed {
      font-size: 0;
    }
  }

  & span {
    transition: 0.2s all;
  }
}