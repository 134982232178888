$gutter: 25;

.panel,
.MuiAccordion-root.panel {
  @extend .card;

  &--transparent {
    border-radius: none;
    box-shadow: none !important;
    background: transparent !important;

    & .panel__summary {

      // min-height: rem-calc(48) !important;
      @include breakpoint(xs) {
        padding: 0;
      }

      // border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }

    & .panel__summary>div {
      margin: 0 !important;

      @include breakpoint(xs) {
        padding: 0;
      }
    }

    & .panel__body {
      @include breakpoint(xs) {
        padding: 0;
      }
    }
  }
}

.panel__summary {
  font-size: 95%;
  color: $text-primary;
  font-weight: 400;
}

.panel__header {
  @extend .card__header;

  & .icon {
    margin-right: rem-calc(10);
    line-height: 1;
  }
}

.panel__title {
  text-transform: uppercase;
  letter-spacing: rem-calc(0.5);
  font-weight: 400;
  color: get-color(primary);
  font-size: rem-calc(18);
}

.panel__body {
  @extend .card__body;
}

// .panel__header + .panel__body {
//   padding-top: rem-calc($gutter/2);
// }