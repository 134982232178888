.list {
  @include mk-flexbox(column);
}

.list__subheader {
  padding: rem-calc($gutter-base/4);
}

.list__item {
  // padding: 0 rem-calc($gutter-base/3);
  transition: all 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  white-space: normal !important;
  height: auto !important;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(black, 0.05);
  }

  &--small {
    font-size: 90%;
  }

  &>.content {
    padding: rem-calc($gutter-base/3);
  }
}

// .list-item__icon {
//   margin-right: rem-calc(15) !important;
// }
.list-item__icon+.list-item__text {
  padding-left: 0 !important;
}

.list-item__text {
  font-family: $body-font-family;
  color: $text-primary;

  & .primary,
  & .secondary {
    font-family: $body-font-family;
    margin-bottom: 0;
    line-height: 1.3;
  }

  & .primary {
    font-weight: 600;
    color: get-color(primary);
    font-size: rem-calc(16);
  }

  & .secondary {
    font-size: rem-calc(14);
    color: $text-secondary;
  }
}