@include mk-spacing();

.main-grid {
    @include mk-flexbox($grid: true);
    margin: 0 auto;
    height: auto;
    min-height: 100%;
}

.grid {
    display: block;
    position: relative;
    width: 100%;
}

.grid-wrapper {
    @extend .grid;
    overflow: hidden;
}

.grid__container {
    @include mk-flexbox(row, $justify:flex-start);

    &--column {
        flex-direction: column;
        float: none;

        &>.grid__item {
            width: 100%;
        }
    }

    &--wrap {
        flex-wrap: wrap;
    }
}

.grid__item {
    &:not(.divisor):empty {
        display: none;
    }

    &.auto {
        max-width: none;
        flex: auto;
    }

    &>.grid__container {
        min-height: 100%;
    }
}