html {
  min-height: 100%;
}

body {
  font-family: $body-font-family;
  min-height: 100vh;

  position: relative;
  overflow-x: hidden;
  line-height: 1.4;
  background-color: #f5f5f5 !important;
}

html,
body {
  height: 100%;
}

iframe {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: $body-font-family;

  &:empty {
    display: none;
  }
}

small {
  transition: 0.2s all;
}

label,
label * {
  font-family: $body-font-family;
}

a {
  transition: color 0.3s;
  text-decoration: none;
  color: get-color(primary);

  &:hover {
    color: inherit;
  }
}

main {
  overflow-x: hidden;
}

p {
  text-align: inherit;
  margin-top: 0;
  margin-bottom: rem-calc(16);
  font-size: $global-font-size;

  &.big {
    font-size: 120%;
  }

  &.bigger {
    font-size: 140%;
  }

  &.small {
    font-size: 90%;
    margin-bottom: rem-calc(10);
  }

  &.smaller {
    font-size: 80%;
    margin-bottom: rem-calc(8);
  }

  &:only-child,
  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  padding-left: rem-calc(20);
}

*:focus {
  outline: none;
}

.text-mutted {
  color: $medium-gray;
}

.xs-collumn-reverse {
  @include breakpoint(xs only) {
    flex-direction: column-reverse;
  }
}

.pointer {
  cursor: pointer;
}

@include breakpoint(retina) {
  @include breakpoint(xs only) {
    html {
      font-size: 103%;
    }
  }

  @include breakpoint(xl) {
    html {
      font-size: 103%;
    }
  }
}

/* --------------------------------------------------
Wrapper
-------
Override any wrapper
-------------------------------------------------- */
.wrapper {
  transition: 0.2s all;
  width: 100%;
  background: transparent !important;
  color: inherit !important;
  max-height: 100%;

  &--hidden {
    overflow: hidden;
  }
}

/* --------------------------------------------------
Map
-------------------------------------------------- */
#map {
  width: 100%;
  height: 60vh;
}

/* --------------------------------------------------
Parallax
-------------------------------------------------- */
.parallax {
  height: inherit;
  min-height: 60vh;
  width: inherit;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(xl) {
    background-position: center center;
    background-attachment: fixed;
  }
}

/* --------------------------------------------------
Elevation
-------------------------------------------------- */
$elevation-base: 0 2px 2px 0 rgba(0, 0, 0, 0.08),
0 3px 1px -2px rgba(0, 0, 0, 0.07),
0 1px 5px 0 rgba(0, 0, 0, 0.1);
$elevation-high: 0 14px 30px -8px rgba(0, 0, 0, 0.4);
$elevation-higher: 0 20px 60px 0 rgba(0, 0, 0, 0.25);

%elevate {
  transition: box-shadow 0.2s, transform 0.2s;
  box-shadow: $elevation-base;
  z-index: 1;

  &hover:not(.nohover) {
    box-shadow: $elevation-high;
  }
}

%elevate-high {
  @extend %elevate;
  transform: scale3d(1.04, 1.04, 1.04);
  box-shadow: $elevation-high;
  z-index: 2;

  @include breakpoint(xl down) {
    transform: scale3d(1.02, 1.02, 1.02);
  }
}

%elevate-higher {
  @extend %elevate;
  transform: scale3d(1.1, 1.1, 1.1);
  box-shadow: $elevation-higher;
  z-index: 3;

  @include breakpoint(xl down) {
    transform: scale3d(1.08, 1.08, 1.08);
  }
}

/* --------------------------------------------------
Aspect Ratio
-------------------------------------------------- */
%ratio {
  @include mk-ratio();

  & %ratio__container,
  & %ratio__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  & %ratio__bg {
    object-fit: cover;
    background-position: center center;
    background-size: cover;
  }

  &--square {
    @include mk-ratio('square');
  }

  &--circle {
    @include mk-ratio('square');
    border-radius: 100%;
  }

  &--portrait {
    @include mk-ratio('portrait');
  }

  &--wide {
    @include mk-ratio('wide');
  }
}

.remove-ratio {
  & .media-wrapper {
    height: 100%;
  }
}

.hoverFadeIn {
  opacity: 0.5;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
  }
}

.shrink {
  height: auto;
  max-height: 100vh;
  overflow: hidden;
  transition: all 0.5s;

  &.in {
    max-height: 0;
  }
}

.defaultIconClass {
  opacity: 0.45;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

$shadow-fill: inset 0 0 0 1000px rgba(black, 0.1);
$shadow-fill-off: inset 0 0 0 1000px rgba(black, 0);

$shadow-drop-close: 0 1px 2px rgba(black, 0.25);
$shadow-drop-close-off: 0 1px 2px rgba(black, 0);

$shadow-drop-far: 0 10px 19px rgba(black, 0.2);
$shadow-drop-far-off: 0 3px 5px rgba(black, 0);

$shadow-drop-inset: inset 0 3px 5px rgba(black, 0.25);
$shadow-drop-inset-off: inset 0 3px 5px rgba(black, 0);

.floater {
  transition: 0.2s all;

  transform: scale(1.05);

  box-shadow: $shadow-drop-inset-off, $shadow-drop-far;

  &:hover,
  &:focus {
    border-color: rgba(black, 0.15);
    box-shadow: $shadow-drop-inset-off, $shadow-drop-close-off, $shadow-fill;
    transform: scale(1);
  }

  &:active {
    box-shadow: $shadow-drop-inset, $shadow-drop-close-off, $shadow-fill;
    transition: 0.1s all;
    transform: scale(1);
  }
}

.quote {
  @include mk-flexbox(row, $align: center, $justify: flex-start);
  background: rgba(0, 0, 0, 0.02);
  position: relative;
  margin-bottom: rem-calc(16);

  & .content {
    @extend .body_2;
    padding: rem-calc(5);
    z-index: 1;
  }

  & .icon {
    z-index: 0;
    width: rem-calc(48);
    height: rem-calc(48);
    opacity: 0.15;
    position: relative;
    float: left;

    &.open {
      top: 0;
      left: 0;
      transform: rotate(180deg);
    }

    // &.close {
    //   bottom: 0;
    //   right: 0;
    // }
  }
}

:disabled,
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: $medium-gray !important;
}

.divisor {
  &:before {
    content: '';
    display: block;
    background: rgba(black, .05);
  }

  &--v {
    &:before {
      width: 1px;
      height: 100%;
    }
  }

  &--h {
    &:before {
      height: 1px;
    }
  }
}