.sidebar {
  width: $sidebar-width;
  padding: 0;
  & * {
    font-family: $body-font-family;
  }
  &__link {
    display: block;
    text-decoration: none;
    color: $text-primary;
    opacity: 0.5;
    & span {
      color: inherit !important;
    }
    & svg {
      color: inherit;
      font-size: rem-calc(22);
    }
    &.active {
      color: $text-emphasis !important;
      opacity: 1;
      & span {
        font-weight: 600;
      }
    }
  }

  &__logo {
    padding: $gutter-base;
    text-align: center;

    & img {
      width: auto;
      height: 100%;
      max-height: rem-calc(100);

      @include breakpoint(xs only) {
        max-height: rem-calc(80);
      }
    }
  }
}
