.login-page {
  @include mk-flexbox($align: center, $justify: center);
  min-height: 100vh;
  height: 100%;
}

.login__box {
  width:100%;
  max-width: rem-calc(450);
  padding: rem-calc($gutter-base/3);
}

@include breakpoint(xs only) {
  .login-page {
    align-items: flex-start;
    padding: rem-calc($gutter-base/3);
  }
}