$colors:(bad: (color:#ea5b3a, width:25%),
    medium: (color:#ffcf3f, width:50%),
    good: (color:#45b0ee, width:75%),
    strong: (color:#57c754, width:100%));

.pass-strength {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    overflow: hidden;
    height: auto;
    max-height: 0;
    transition: 1s;
    margin-top: 8px;

    &.showing {

        .pass-strength__tips-container {
            opacity: 1;
            margin-top: 16px;
            overflow: visible;
        }
    }

    &__tips-container {
        visibility: hidden;
        overflow: visible;
        height: auto;
        max-height: 0;
        transition: 1s;
        opacity: 0;
    }

    &__bar {
        position: relative;
        height: 4px;
        margin-bottom: 4px;
        width: 100%;
        border-radius: 200px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 0;
            border-radius: 200px;
            transition: .3s all;
        }

    }

    &__tips {
        margin: 0;

        &__title {
            margin-bottom: 0;
            font-weight: bold;
        }
    }

    &__tip {
        margin-bottom: 0;
        transition: 1s all;

        &--check {
            text-decoration: line-through;
            color: rgba(black, 0.2)
        }
    }
}

@each $name,
$map in $colors {
    .pass-strength.#{$name} {
        .pass-strength__bar:before {
            width: map-get($map, width);
            background-color: map-get($map, color);
        }

        .pass-strength__bar-description {
            color: darken(map-get($map, color), 30);
            font-weight: 400;
            margin: 0;
        }

    }
}