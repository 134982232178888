.swipeable__view {
  height: auto;
  transition: 0.8s ease-in;
  overflow-y: auto;
  max-height: 100vh;
  opacity: 1;

  &.hidden {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
  }
}
