$gutter: 24;

.dialog {
  font-family: $body-font-family;
  color: rgba($text-primary, 0.75);
  font-weight: 400;
}

.dialog__icon {
  font-size: rem-calc(48) !important;
  margin-bottom: rem-calc(15);

  & svg {
    font-size: inherit !important;
    color: inherit;
  }
}

.dialog__body,
.dialog__header {
  padding-bottom: rem-calc($gutter);
}

.dialog__title {
  position: relative;
  margin: 0;

  letter-spacing: rem-calc(0.5);
  color: $text-emphasis;
  font-family: $body-font-family;

  text-transform: uppercase;
  font-size: rem-calc(18);
  font-weight: 300;

  & span {
    display: inline-block;
    vertical-align: middle;
  }
}

.dialog__subtitle {
  position: relative;
  color: $text-secondary;
  font-family: $body-font-family;
  font-size: rem-calc(14);
  font-weight: 500;
  margin: 0;
}

.dialog__action-btns {
  @extend .card__action-btns;
  justify-content: flex-end;
  padding-bottom: rem-calc(16);
}

.grid,
.grid__container,
.grid__item {
  .dialog__action-btns {
    padding: 0;
  }
}

// .dialog__title + .dialog__subtitle {
//   margin-top: rem-calc(5)
// }

.dialog__bullets {
  @include mk-flexbox(row, $justify: center);
  padding: rem-calc(16) 0;

  @include breakpoint(xs only) {
    padding-top: 0;
  }

  & .bullet {
    margin: rem-calc(5);
    width: 6px;
    height: 6px;
    background-color: $light-gray;
    transition: 0.2s all;
    border-radius: rem-calc(16);
    border-radius: 50%;

    &.active {
      background-color: get-color(primary);
    }

    &.clickable {
      cursor: pointer;
    }
  }
}

.dialog__greetings {
  font-size: rem-calc(24);
  font-weight: bold;
  color: $text-primary;

  & .member {
    color: get-color(primary);
  }
}

.dialog__featured_img {
  max-width: 40%;
  float: left;
  padding-right: rem-calc(16);

  &--full-width {
    float: none;
    max-width: none;
  }

  @include breakpoint(xs only) {
    float: none;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: rem-calc(16);
  }
}

.dialog img.full-width {
  float: none;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto !important;
}