.media-wrapper {
  background: transparent;
  backface-visibility: hidden;

  @extend %ratio;

  &--square {
    @extend %ratio--square;
  }
  &--portrait {
    @extend %ratio--portrait;
  }
  &--wide {
    @extend %ratio--wide;
  }
  &--circle {
    @extend %ratio--circle;
  }

  & .media {
    @extend %ratio__bg;
    width: 100%;
    height: 100%;

    &--contain {
      object-fit: contain;
      max-width: 100%;
      height: auto;
    }
  }
}

.media {
  transition: transform 10s linear;
  max-width: none;
  backface-visibility: hidden;
}

.media-wrapper__footer {
  position: absolute;
  bottom: 0;

  width: 100%;

  background: rgba(white, 0.2);

  color: $body-font-color;
}
