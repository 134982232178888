.tooltip {
  font-size: rem-calc(13.5);
  padding: rem-calc(10);
  line-height: 1.4;
  & p {
    margin-bottom: rem-calc(8);
  }
  & ul {
    margin: 0;
  }
  & li:not(:last-of-type) {
    margin-bottom: rem-calc(5);
  }
}
