$input-height: rem-calc(30);

%clear-default {
  outline: none !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.base-field {
  @extend %clear-default;
  height: auto;
  min-height: $input-height;
  margin: rem-calc(20) 0 rem-calc(6);
  padding: 0;
  flex: 1;

  color: inherit;
  font-size: rem-calc(16);
  font-weight: 500;
  line-height: 1.4;
  transition: .2s all;

  cursor: default;

  z-index: 1;
}

input:not(.MuiInputBase-input):not([type=button]):not([type=checkbox]):not([type=radio]):not([type=file]):not([type=submit]):not([type=color]),
textarea,
select {
  @extend .base-field;
  cursor: text;
  min-width: 0px;
  width: 100%;
}

select {
  cursor: pointer;
  background-image: url('../../img/angle-down-solid.svg');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: rem-calc(15) rem-calc(15);
  padding-right: rem-calc(25);
}

input[type=color] {
  height: $input-height !important;
}

input[type=number] {
  text-align: right;
}

.form {
  width: 100%;
  max-width: rem-calc(600);
}


%adornment {
  position: absolute;
  opacity: .54;
  transition: .2s all;
  transform: translate(0, rem-calc(24)) scale(1);
  top: 0;
  line-height: inherit;
  font-size: inherit;
  z-index: 0;
}



.form-control {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  width: 100%;

  text-align: left;
  font-size: rem-calc(16);
  color: $text-primary;

  transition: .2s all;

  &.hidden {
    display: none;
  }

  label {
    font-weight: 500;
    position: relative;
    display: block;
    font-size: inherit;
    width: 100%;
  }

  label:not(.float-label) {
    &+.base-field {
      margin-top: rem-calc(3);
      padding-top: 0;
    }
  }

  ul {
    margin: 0;
    padding: rem-calc(8) 0;
  }

  .float-label {
    @extend %adornment;
    transform-origin: top left;
    left: rem-calc(5);
  }

  .label-up {
    transform: translate(0, 0);
    top: 0;
    left: 0;
    font-style: normal;
    font-size: 80%;

    // &+.switch {
    //   margin-top: rem-calc(18);
    // }
  }

  .float-label,
  .label-up {
    opacity: .54;
  }

  &.is-filled,
  &.is-active {
    .float-label {
      @extend .label-up;
    }
  }

  &.is-active {
    .float-label {
      opacity: 1;
    }
  }

  &.is-active {

    .field-wrapper {

      &:not(.outlined):before {
        background-color: get-color(primary);
        height: 2px;
      }

      &.outlined {
        border: 2px solid get-color(primary);

        &.rounded {
          border-radius: 3px;
        }
      }
    }
  }

  &.error {

    .field-wrapper {

      &:not(.outlined):before {
        background-color: get-color(alert);
      }

      &.outlined {
        border-color: get-color(alert);
      }
    }
  }

  /* .field-wrapper adornment */
  .with-adornment {
    &--start {

      .base-field {
        padding-left: rem-calc(30) !important;
      }

      & label {
        left: rem-calc(30) !important;
      }
    }

    &--end {

      .base-field {
        padding-right: rem-calc(30) !important;
      }

      & label {
        left: rem-calc(30) !important;
      }
    }
  }

  .adornment {
    @extend %adornment;
    transform-origin: top left;
    left: rem-calc(5);

    &--end,
    &~.adornment {
      transform-origin: top right;
      left: auto;
      right: rem-calc(5);
    }

    &--clickable {
      z-index: 1;
    }

    &.icon-btn {
      cursor: pointer;
      border-radius: $input-height;
      height: $input-height;
      width: $input-height;
      line-height: $input-height;
      padding: 0;
      right: 0;
      transform: translateY(rem-calc(17));
    }

  }

  &.is-active {

    label,
    .adornment {
      color: get-color(primary);
      opacity: 1;
    }
  }






  .field-wrapper {
    @include mk-flexbox(row, $align:baseline);

    &:not(.outlined):before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: get-color(light-gray);
      height: 1px;
      transition: all .2s;
      width: 100%;
    }

    .switch-wrapper {
      @extend .base-field;

      label {
        cursor: pointer;
      }
    }

    &--readonly,
    &--checkbox {
      .base-field {
        display: flex;
        align-items: center;
      }

      &:before {
        display: none;
      }
    }



    &.outlined {
      border: 1px solid get-color(light-gray);
      transition: all .2s;

      &.rounded {
        border-radius: 3px;
      }
    }

    .field-wrapper__select {
      padding-right: rem-calc(25);
    }

    label.float-label {
      cursor: text;
    }
  }

  label.select-wrapper {
    @extend .base-field;
    position: relative;
    width: 100%;
    cursor: pointer;

    select {
      width: 100%;
      padding: 0;
      padding-right: rem-calc(24);
    }

    &:before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-angle-down);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .select-icon {
    @extend .fa-angle-down;
    @extend .fas;
    // padding: 0 rem-calc(5);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: rem-calc(20);
    height: rem-calc(20);
    z-index: -1;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover,
  &.is-active {

    .field-wrapper {
      &:not(.outlined):before {
        height: 2px;
      }

      &.outlined {
        border-width: 2px;
      }
    }
  }

  &.is-active {

    .field-wrapper {
      &:not(.outlined):before {
        background-color: get-color(primary);
      }

      &.outlined {
        border-color: get-color(primary)
      }
    }
  }




  // input:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=file]),
  // textarea,
  // select {
  //   @extend .base-field;
  // }


  // label:not(.float-label) {

  //   &~input:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=file]),
  //   &~textarea,
  //   &~select,
  //   &~.base-field {
  //     padding: rem-calc(4) 0 0;
  //   }
  // }



  // select {
  //   display: inline-block;
  //   width: 100%;

  //   &~select {
  //     margin: 0 rem-calc(2.5);
  //   }
  // }


  &.error {

    .base-field {
      border-bottom-color: get-color(alert) !important;
    }
  }



}

.form__subtitle {
  font-size: rem-calc(17);
  color: $medium-gray;
  margin-bottom: rem-calc(15);
  font-weight: bold;
}

.form-response {
  padding: rem-calc($gutter-base);
  margin-bottom: rem-calc($gutter-base);

  &.success {
    @extend .bg--success;
  }

  &.error {
    @extend .bg--alert;
  }

  &:empty {
    display: none;
  }
}

.form-messages {
  margin: 0;
  margin-bottom: rem-calc($gutter-base);
  padding: 0;

  & .message {
    padding: rem-calc($gutter-base);
    transition: .2s all;
  }

  & li {
    list-style: none;
  }
}

.required {
  label:not(.btn):after {
    content: '*' !important;
    display: inline-block !important;
    color: get-color(alert) !important;
    vertical-align: middle !important;
    height: inherit !important;
    font-size: inherit;
    top: 0;
    font-weight: bolder;
    padding-left: rem-calc(4);
  }
}

@include mk-component-palette('.message');


// .form-control__radio {
//   @extend .btn;
//   border-bottom: none;


//   & input[type=radio]:checked {
//     @extend 
//     // margin-top: rem-calc(24);
//     // margin-bottom: rem-calc(6);
//     // border: none;
//     // transition: .2s all;
//     // width: 100%;
//     // background: transparent;
//     // color: inherit;
//     // font-size: inherit;
//   }
// }




// .form-control__btn {
//   color: inherit;
//   cursor: pointer;
// }

.form-control__action-btns {
  @include mk-flexbox(row, $align:center);
  margin: rem-calc(-4);
  margin-top: rem-calc($gutter-base);

  & .action-btn {
    margin: rem-calc(4)
  }
}

.helper-text {
  position: relative;
  display: block;
  font-size: rem-calc(11.5);
  width: 100%;
  transition: all .2s ease-in-out;
  color: rgba($text-primary, .54);
  margin-top: rem-calc(3);

  &:empty {
    display: none;
  }
}

.error .helper-text,
.helper-text.error,
.helper-text .errorlist {
  color: get-color(alert);
  font-weight: bold;
}

.helper-text {
  ul.errorlist {
    padding: 0;

    li {
      margin: 0;
      list-style: none;
    }
  }

}

// .errorlist {
//   margin: 0;
//   padding: 0;

//   & li {
//     list-style: none;
//     margin: inherit;
//     padding: inherit;
//   }
// }


// .form-control__captcha {
//   position: relative;

//   &:after {
//     border-bottom-width: 0 !important;
//   }

//   &>div {
//     display: inline-block;
//     position: relative;
//   }
// }

// button[type='submit'] {
//   position: relative;

//   &:after {
//     content: '';
//     background-image: url('../img/bars.gif');
//     background-position: 98%;
//     background-repeat: no-repeat;
//     background-size: contain;
//     display: none;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     opacity: 0;
//   }
// }

// form.loading {
//   & button[type='submit'] {
//     padding-right: rem-calc(60);

//     &:after {
//       opacity: 1;
//       display: block;
//     }
//   }
// }

label input[type=file] {
  display: none;
}




/* ----------------------------------------------------------------------------
Input
---------------------------------------------------------------------------- */


/** Checkbox **/
input[type=checkbox]:not(.switch__checkbox) {
  &+label {
    @extend .fa-square;

    &:before {
      @extend .far;
      margin: rem-calc(5)
    }
  }

  &:checked {
    &+label {
      @extend .fa-check-square;

      &:before {
        @extend .fas;
        margin: rem-calc(5)
      }
    }
  }
}


/** Radio **/
input[type=radio] {

  &+label:not(.btn) {
    &:before {
      @extend .fa-circle;
      @extend .far;
      margin: rem-calc(5)
    }
  }

  &:checked {
    &+label:not(.btn) {
      &:before {
        @extend .fa-dot-circle;
        @extend .fas;
        margin: rem-calc(5)
      }
    }

    &+.btn {
      cursor: not-allowed;
      pointer-events: none;

      &:not(.btn--image) {
        @extend .flat;
      }

      &--image {
        @extend .fa-check;

        &:after {
          content: '';
          background-color: smart-scale(get-color(primary), 30%);
          opacity: .3;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        &:before {
          @extend .fas;
          position: absolute;
          background: get-color(primary);
          color: color-pick-contrast(get-color(primary), ($white, $black), $tolerance:0);
          border-radius: 50%;
          top: rem-calc(-4);
          right: rem-calc(-4);
          z-index: 2;
          font-size: rem-calc(14);
          padding: rem-calc(5);
        }
      }
    }
  }
}

/** 
Both 
Needs JS to add a label after if it doesnt exists 
*/
input[type=checkbox]:not(.switch__checkbox),
input[type=radio] {
  display: none !important;

  &+label {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &:before {
      font-size: 120%;
      color: get-color(text-secondary);
    }
  }

  &:checked {
    &+label {
      &:before {
        color: get-color(primary);
      }
    }
  }
}


.flatpickr-calendar {

  select,
  input {
    margin: 0 !important;
  }
}

.flatpickr-calendar input[type=number] {
  text-align: center !important;
}

/* ----------------------------------------------------------------------------
Media Queries
---------------------------------------------------------------------------- */
@include breakpoint(xs only) {
  .form {
    padding-bottom: rem-calc(50);
  }
}