.alert {
  &--success {
    background-color: get-color(success) !important;
    color: white !important;
  }
  &--danger {
    background-color: get-color(alert) !important;
    color: white !important;
  }
  &--info {
    background-color: get-color(info) !important;
    color: white !important;
  }
  &--warning {
    background-color: get-color(warning) !important;
    color: white !important;
  }
  & > div {
    background-color: inherit !important;
    color: inherit !important;
  }
}
