@mixin mk-text-pallete() {

  @each $key,
  $val in $foundation-palette {

    .--t-#{""+$key},
    .--t-#{""+$key} * {
      color: $val !important;
    }
  }
}

@include mk-text-pallete();

// --- Text Align

@mixin text-modifiers() {
  $alignments: (left, center, right, justify);

  @each $alignment in $alignments {
    .--ta-#{$alignment} {
      text-align: $alignment;
    }

    @each $name,
    $size in $breakpoints {
      @include breakpoint($name) {
        .--ta-#{$name}-#{$alignment} {
          text-align: $alignment;
        }
      }
    }

  }
}

@include text-modifiers();

// --- Text Transform
[class*='--upper'] {
  text-transform: uppercase;
}

[class*='--nocase'] {
  text-transform: none;
}

[class*='--t-zero'] {
  font-size: 0 !important;
}


[class*='--t-bold'] {
  font-weight: 600;
}

[class*='--title'] {
  font-size: rem-calc(20);
  font-weight: 600;
  margin-bottom: 0;
}

[class*='--subheading'] {
  font-size: rem-calc(18);
  font-weight: 400;
  margin-bottom: 0;
}