.collapsible {

    &.collapsed {

        [data-collapse] {
            display: none;
        }

        .collapse-icon {
            transform: translateY(-50%);
        }
    }
}

.collapse-btn {
    display: block;
    text-align: left;
    padding-right: rem-calc(40);
    position: relative;
    cursor: pointer;

    .collapse-icon {
        position: absolute;
        right: rem-calc(20);
        top: 45%;
        transform: translateY(-50%) rotate(180deg);
    }
}